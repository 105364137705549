<template>
  <div class="g_page_box">
    <div class="g_main_content" style="overflow-x: hidden; width: 100%">
      <div class="risk-circles">
        <div class="circles-box">
          <div class="circles-box-item circles-box-item-1"></div>
          <div class="circles-box-item circles-box-item-2"></div>
          <div class="circles-box-item circles-box-item-3"></div>
        </div>
      </div>
      <div class="risk-header">
        <div class="risk-header-title">
          <h3>我发现了 <span>3</span> 个健康风险</h3>
          <p>由您和家人的健康信息综合分析得出</p>
        </div>
        <div class="risk-header-ai">
          <img src="../../assets/img/risk/img_ai_avatar@2x.png" />
        </div>
      </div>
      <div class="risk-list">
        <div class="risk-list-item yellow">
          <div class="risk-list-item-circle"></div>
          <div class="risk-list-item-box">
            <div class="risk-list-item-box-avatar">
              <img src="../../assets/img/home/img-user-avatar.png" alt="" />
            </div>
            <div class="risk-list-item-box-info">
              <span>妻子 李瑞华</span>
              <h1>多囊卵巢综合征风险</h1>
              <p>发现时间： 3天前</p>
            </div>
            <div class="risk-list-item-box-tips">中风险</div>
          </div>
        </div>
        <div class="risk-list-item blue">
          <div class="risk-list-item-circle"></div>
          <div class="risk-list-item-box">
            <div class="risk-list-item-box-avatar">
              <img src="../../assets/img/home/img-user-avatar.png" alt="" />
            </div>
            <div class="risk-list-item-box-info">
              <span>妻子 李瑞华</span>
              <h1>多囊卵巢综合征风险</h1>
              <p>发现时间： 3天前</p>
            </div>
            <div class="risk-list-item-box-tips">中风险</div>
          </div>
        </div>
        <div class="risk-list-item red">
          <div class="risk-list-item-circle"></div>
          <div class="risk-list-item-box">
            <div class="risk-list-item-box-avatar">
              <img src="../../assets/img/home/img-user-avatar.png" alt="" />
            </div>
            <div class="risk-list-item-box-info">
              <span>妻子 李瑞华</span>
              <h1>多囊卵巢综合征风险</h1>
              <p>发现时间： 3天前</p>
            </div>
            <div class="risk-list-item-box-tips">中风险</div>
          </div>
        </div>
      </div>
      <div data-v-43c1de53="" class="bottom-bar">
        <div data-v-43c1de53="" class="bottom-bar-text">
          邀请家人,家庭健康全掌控
        </div>
        <div data-v-43c1de53="" class="bottom-bar-btn">邀请家人</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
    };
  },
};
</script>

<style scoped>
.bottom-bar {
  position: fixed;
  width: 100%;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06);
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
}

.bottom-bar-btn {
  width: 102px;
  height: 34px;
  background: #0088ff;
  opacity: 1;
  border-radius: 6px;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff;
  text-align: center;

  &.disabled {
    background: #ccc;
  }
}

.risk-circles {
  overflow: hidden;
  height: 184px;
  width: 100%;
  position: absolute;
}

.risk-header {
  overflow: hidden;
  height: 124px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.risk-header-ai {
  width: 32%;
  text-align: center;

  & img {
    width: 80px;
    margin-top: 30px;
  }
}

.risk-header-title {
  width: 64%;
  margin-bottom: 14px;

  & h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;

    & span {
      color: #ff6600;
    }
  }

  & p {
    font-size: 12px;
    color: #707070;
  }
}

.risk-list {
  z-index: 999;
  position: relative;
  padding: 0px 18px;
  margin-top: -20px;
}

.risk-list-item {
  background: #fef9ed;
  height: 110px;
  border-radius: 14px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  padding: 20px 14px;
  margin-bottom: 20px;
  overflow: hidden;
}

.risk-list-item-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  top: -100px;
}

.risk-list-item-box-info {
  width: 180px;
  margin-left: 12px;
  line-height: 1.4;

  & span {
    color: #858790;
  }
  & h1 {
    color: #242938;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 600;
  }
  & p {
    color: #9d9d9f;
    font-size: 12px;
  }
}

.risk-list-item-box-tips {
  color: #bfa663;
  text-align: right;
  flex: 1;
  font-weight: 500;
}

.risk-list-item-box-avatar {
  width: 40px;
  text-align: center;

  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px #fff solid;
  }
}

.risk-list-item-circle {
  width: 140px;
  height: 140px;
  position: relative;
  right: -210px;
  margin-top: -40px;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    rgba(255, 234, 198, 1),
    rgba(244, 244, 203, 0.05)
  );
}

.blue {
  background: #e8f8ff;
}
.blue .risk-list-item-circle {
  background: linear-gradient(
    0deg,
    rgba(202, 240, 255, 1),
    rgba(234, 247, 255, 0.05)
  );
}
.blue .risk-list-item-box-tips {
  color: #1989fa;
}

.red {
  background: #ffeaea;
}

.red .risk-list-item-circle {
  background: linear-gradient(
    0deg,
    rgba(255, 217, 217, 1),
    rgba(255, 234, 234, 0.05)
  );
}
.red .risk-list-item-box-tips {
  color: #ee8595;
}

.circles-box {
  width: 120px;
  height: 120px;
  position: absolute;
  right: 14px;
  top: 10px;

  .circles-box-item {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #f7f7f7;
    position: absolute;
  }

  .circles-box-item-1 {
    animation: 8s scaleToggleOne cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    z-index: 9;
  }
  .circles-box-item-2 {
    background: #fff;
    animation: 8s scaleToggleTwo cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    z-index: 8;
  }
  .circles-box-item-3 {
    background: #fff;
    animation: 12s scaleToggleThree cubic-bezier(0.25, 0.46, 0.45, 0.94)
      infinite;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    z-index: 7;
    opacity: 0.6;
  }
}

@keyframes scaleToggleOne {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes scaleToggleTwo {
  0% {
    transform: scale(1.8);
    -webkit-transform: scale(1.8);
  }
  20% {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
  }
  60% {
    transform: scale(1.6);
    -webkit-transform: scale(1.6);
  }
  100% {
    transform: scale(1.8);
    -webkit-transform: scale(1.8);
  }
}
@keyframes scaleToggleThree {
  0% {
    transform: scale(2);
    -webkit-transform: scale(2);
  }
  33% {
    transform: scale(2.4);
    -webkit-transform: scale(2.4);
  }
  66% {
    transform: scale(2.2);
    -webkit-transform: scale(2.2);
  }
  100% {
    transform: scale(2);
    -webkit-transform: scale(2);
  }
}
</style>
